var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"mt-n3"},[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.canSave()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"mt-n1"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.monedasBaseItems,"item-text":"value","item-value":"id","return-object":"","label":"Moneda base","rules":_vm.rules.required,"outlined":"","dense":""},model:{value:(_vm.monedaBaseSelected),callback:function ($$v) {_vm.monedaBaseSelected=$$v},expression:"monedaBaseSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.monedasReferenciaItems,"item-text":"value","item-value":"id","return-object":"","label":"Moneda referencia","rules":_vm.rules.required,"outlined":"","dense":""},model:{value:(_vm.monedaReferenciaSelected),callback:function ($$v) {_vm.monedaReferenciaSelected=$$v},expression:"monedaReferenciaSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"type":"text","label":"Cotización","rules":_vm.rules.required.concat(
                  _vm.rules.nonZero,
                  _vm.rules.maxDigitsDecimal(10, 4)
                ),"dense":"","outlined":""},model:{value:(_vm.cotizacion),callback:function ($$v) {_vm.cotizacion=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"cotizacion"}})],1)],1),_c('v-row',{staticClass:"mt-n1"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-menu',{ref:"vigencia-desde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia desde","append-icon":_vm.calendarIcon,"autocomplete":"not","hint":"Formato DD/MM/AAAA","outlined":"","dense":"","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.vigenciaDesdeSelected)) <=
                        new Date(_vm.parseDateToIso(_vm.vigenciaHastaSelected)) ||
                        'Formato incorrecto'
                    )},on:{"blur":function($event){_vm.vigenciaDesde = _vm.parseDateToIso(_vm.vigenciaDesdeSelected)}},model:{value:(_vm.vigenciaDesdeSelected),callback:function ($$v) {_vm.vigenciaDesdeSelected=$$v},expression:"vigenciaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuVigenciaDesde),callback:function ($$v) {_vm.menuVigenciaDesde=$$v},expression:"menuVigenciaDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.vigenciaDesdeSelected = _vm.formatDate(_vm.vigenciaDesde)},"input":function($event){_vm.menuVigenciaDesde = false}},model:{value:(_vm.vigenciaDesde),callback:function ($$v) {_vm.vigenciaDesde=$$v},expression:"vigenciaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-menu',{ref:"vigencia-hasta",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia hasta","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","outlined":"","autocomplete":"not","dense":"","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.vigenciaDesdeSelected)) <=
                        new Date(_vm.parseDateToIso(_vm.vigenciaHastaSelected)) ||
                        'Formato incorrecto'
                    )},on:{"blur":function($event){_vm.vigenciaHasta = _vm.parseDateToIso(_vm.vigenciaHastaSelected)}},model:{value:(_vm.vigenciaHastaSelected),callback:function ($$v) {_vm.vigenciaHastaSelected=$$v},expression:"vigenciaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuVigenciaHasta),callback:function ($$v) {_vm.menuVigenciaHasta=$$v},expression:"menuVigenciaHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.vigenciaHastaSelected = _vm.formatDate(_vm.vigenciaHasta)},"input":function($event){_vm.menuVigenciaHasta = false}},model:{value:(_vm.vigenciaHasta),callback:function ($$v) {_vm.vigenciaHasta=$$v},expression:"vigenciaHasta"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mt-n7"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }